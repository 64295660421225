<template>
  <div>
    <vs-chip v-for="(item, index) in entity.groups" :key="index" @click="removeGroup(index)" closable>
      {{ item.name }}
    </vs-chip>
    <a href="#" @click.prevent="popup.active=true">
      <vs-chip>
        + Add
      </vs-chip>
    </a>

    <vs-popup :active.sync="popup.active" title="Add" class="overflow-popup-fix">
      <vs-row>
        <vs-col>
          <SelectForeign
              label="Select group"
              :model.sync="popup.item.group"
              placeholder="Type to search"
              :multiple="false"
              :load_initial="true"
              :label_keys="['name', ]"
              :show_id_in_label="true"
              :endpoint="`${$store.state.apiendpoints.entity_group_list}`"
              container_class="w-full"
              input_class="bg-white"
              :use_id_as_value="false"
              :disabled="submit_disabled"
          />
          <small class="d-block text-danger">
            {{ popup.api_errors.group }}
          </small>
        </vs-col>
      </vs-row>
      <vs-row class="mt-3">
        <vs-col class="text-center">
          <vs-button @click="addGroup" :disabled="submit_disabled">Add</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { Request } from '@/utils/requests';

export default {
  props: {
    entity: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      submit_disabled: false,

      popup: {
        active: false,
        item: {
          group: null
        },
        api_errors: {}
      }
    }
  },
  methods: {
    addGroup(){
      if (!this.popup.item.group) {
        this.popup.active = false
      } else {
        let groups = this.entity.groups
        if (groups.find(v => v.id == this.popup.item.group.id)) {
          this.popup.active = false
        } else {
          groups.push(this.popup.item.group)
          this.submit(groups)
        }
      }
    },
    removeGroup(index) {
      this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Are you sure?',
          text: 'Are you sure you want to remove this group?',
          accept: () => this._remove_group(index),
          'accept-text': 'Yes',
          'cancel-text': 'No'
      })
    },
    _remove_group(index) {
      let groups = this.entity.groups
      groups.splice(index, 1)
      this.submit(groups)
    },
    submit(groups) {
      let url = `${this.$store.state.apiendpoints.data_entity}${this.entity.id}/`

      let payload = { groups }

      this.$vs.loading()
      this.submit_disabled = true
      this.api_errors = {}

      Request(this, 'PATCH', url, null, payload).then(res => {
        this.$vs.loading.close()
        this.submit_disabled = false
        this.popup.active = false

        this.$emit('update', res.groups)

        this.$vs.notify({
          time: 2500,
          title: 'Success',
          text: "Updated Successfully!",
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      }, err => {
        this.$vs.loading.close()
        this.submit_disabled = false

        let msg = "An unknown error occurred, please try again!"

        if (err.error) {
          this.popup.api_errors = err.error
          msg = "Please fix the errors and try again!"
        }
        this.$vs.notify({
          time: 2500,
          title: 'Error',
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>

<style lang="scss">
  .overflow-popup-fix {
    .vs-popup--content {
      overflow: visible;
    }
  }
</style>
