<template>
    <div>
        <vs-row class="mb-2" v-if="!hideFilters">
            <vs-col vs-xs="12" vs-sm="9" vs-lg="9" class="px-0 pr-2">
                <vs-input
                    class="w-full"
                    placeholder="Type to Search"
                    v-model="filters.search"
                />
            </vs-col>
            <vs-col vs-xs="12" vs-sm="1" vs-lg="1" class="px-0 pr-2">
                <vx-card class="h-full" no-shadow>
                    <div slot="no-body" class="px-2 py-1">
                        <h5 class="text-center">{{ total_results || 0 }}</h5>
                        <small class="text-primary block text-center">Total</small>
                    </div>
                </vx-card>
            </vs-col>
            <vs-col vs-xs="12" vs-sm="1" vs-lg="1" class="px-1">
                <vx-tooltip text="Refresh Data" color="primary" >
                    <vs-button color="primary" type="filled" class="w-full" @click="init()" :disabled="loading">
                        <font-awesome-icon :icon="redoIcon" />
                    </vs-button>
                </vx-tooltip>
            </vs-col>
            <vs-col vs-xs="12" vs-sm="1" vs-lg="1" class="px-1">
                <vx-tooltip text="Create New" color="primary">
                    <router-link :to="{name: 'entity-add'}">
                        <vs-button color="primary" type="filled" class="w-full" :disabled="loading">
                            <font-awesome-icon :icon="plusIcon" />
                        </vs-button>
                    </router-link>
                </vx-tooltip>
            </vs-col>
        </vs-row>
        <vs-row class="mb-base" v-if="!hideFilters">
            <vs-col vs-xs="12" vs-sm="3" vs-lg="2" class="px-0 pr-2 mb-1">
                <InputSelect
                    :model.sync="filters.active"
                    input_class="w-full bg-white"
                    label="Filter by Active"
                    :options="active_options"
                    placeholder="Click to Select"
                />
            </vs-col>
            <vs-col vs-xs="12" vs-sm="4" vs-lg="2" class="px-0 pr-2 mb-1">
                <InputSelect
                    :model.sync="filters.entity_type"
                    input_class="w-full bg-white"
                    label="Filter by Entity Type"
                    :options="entity_type_options"
                    placeholder="Click to Select"
                />
            </vs-col>
            <vs-col vs-xs="12" vs-sm="3" vs-lg="2" class="px-0 pr-2 mb-1">
                <InputSelect
                    :model.sync="filters.is_foreign"
                    input_class="w-full bg-white"
                    label="Filter by Foreign"
                    :options="foreign_options"
                    placeholder="Click to Select"
                />
            </vs-col>
            <vs-col vs-xs="12" vs-sm="3" vs-lg="3" class="px-0 pr-2 mb-1">
                <SelectForeign
                    label="Filter by Tags"
                    :model.sync="filters.tags"
                    placeholder="Type to search"
                    :multiple="false"
                    :load_initial="true"
                    :label_keys="['name',]"
                    :show_id_in_label="false"
                    :endpoint="`${$store.state.apiendpoints.entity_tag_list}`"
                    container_class="w-full"
                    input_class="bg-white"
                    :use_id_as_value="true"
                />
            </vs-col>
            <vs-col vs-xs="12" vs-sm="3" vs-lg="3" class="px-0 pr-2 mb-1">
                <SelectForeign
                    label="Filter by Groups"
                    :model.sync="filters.groups"
                    placeholder="Type to search"
                    :multiple="false"
                    :load_initial="true"
                    :label_keys="['name',]"
                    :show_id_in_label="false"
                    :endpoint="`${$store.state.apiendpoints.entity_group_list}`"
                    container_class="w-full"
                    input_class="bg-white"
                    :use_id_as_value="true"
                />
            </vs-col>
        </vs-row>

        <div class="vx-col w-full mb-base">
            <vx-card>
                <vs-table :data="results" slot="no-body" hoverFlat noShadow>
                    <template slot="thead">
                        <template v-if="viewMode == 'compact'">
                            <vs-th>Legully ID</vs-th>
                            <vs-th>Name</vs-th>
                            <vs-th>Active</vs-th>
                            <vs-th></vs-th>
                        </template>
                        <template v-else>
                            <vs-th>Legully ID</vs-th>
                            <vs-th>Name</vs-th>
                            <vs-th>Entity Type</vs-th>
                            <vs-th>Active</vs-th>
                            <vs-th>Tags</vs-th>
                            <vs-th>Groups</vs-th>
                            <vs-th></vs-th>
                        </template>
                    </template>
                    <template>
                        <template v-for="(item, index) in results">
                            <vs-tr :key="`compact-${item.id}`" v-if="viewMode == 'compact'">
                                <vs-td>
                                    {{ item.id }}
                                </vs-td>
                                <vs-td>
                                    {{ item.name }}
                                    <small class="block">
                                        <strong class="text-primary">
                                            Type:
                                        </strong>
                                        <FormatText :text="item.entity_type" format="select" :kwargs="entity_type_options" />
                                    </small>
                                </vs-td>
                                <vs-td>
                                    <font-awesome-icon :icon="checkIcon" class="text-success" v-if="item.active" />
                                    <font-awesome-icon :icon="crossIcon" class="text-danger" v-else />
                                </vs-td>
                                <vs-td class="text-right">
                                    <router-link :to="{name:'view-entity', params: {id: item.id}}" v-if="item.active">
                                        <vs-button color="primary" type="filled" size="small" class="px-2">
                                            View
                                        </vs-button>
                                    </router-link>
                                </vs-td>
                            </vs-tr>
                            <vs-tr :key="item.id" v-else>
                                <vs-td>
                                    {{ item.id }}
                                </vs-td>
                                <vs-td>
                                    {{ item.name }}
                                </vs-td>
                                <vs-td>
                                    <FormatText :text="item.entity_type" format="select" :kwargs="entity_type_options" />
                                </vs-td>
                                <vs-td>
                                    <font-awesome-icon :icon="checkIcon" class="text-success" v-if="item.active" />
                                    <font-awesome-icon :icon="crossIcon" class="text-danger" v-else />
                                </vs-td>
                                <vs-td>
                                  <InlineEntityTagEditor :entity="item" />
                                </vs-td>
                                <vs-td>
                                    <InlineEntityGroupEditor :entity="item" />
                                </vs-td>
                                <vs-td class="text-right">
                                    <vs-button
                                        color="danger" type="filled" size="small"
                                        class="mx-2"
                                        @click="disableEntity(index)"
                                        :disabled="item.submit_disabled === true"
                                        v-if="item.active"
                                    >
                                        Disable
                                    </vs-button>
                                    <vs-button
                                        color="success" type="filled" size="small"
                                        class="mx-2"
                                        @click="enableEntity(index)"
                                        :disabled="item.submit_disabled === true"
                                        v-else
                                    >
                                        Enable
                                    </vs-button>
                                    <router-link :to="{name:'view-entity', params: {id: item.id}}" v-if="item.active">
                                        <vs-button color="primary" type="filled" size="small">
                                            View
                                        </vs-button>
                                    </router-link>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </template>
                </vs-table>
            </vx-card>
        </div>
        <template v-if="!disablePagination">
            <vs-row v-if="total_results" class="mt-3 text-center">
                <vs-pagination :total="total_pages" v-model="current_page" goto class="mx-auto"></vs-pagination>
            </vs-row>
        </template>
    </div>
</template>


<script>
import { faCheckCircle, faPlus, faRedo, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Request } from '../../../utils/requests';

import InlineEntityGroupEditor from '../Groups/InlineEntityGroupEditor.vue';
import InlineEntityTagEditor from '../Tags/InlineEntityTagEditor.vue';

export default {
    props: {
        embedded_mode: {
            required: false,
            default: false
        },
        embedded_filters: {
            required: false,
            default: () => {}
        },
        disablePagination: {
            type: Boolean,
            required: false,
            default: false
        },
        global_mode: {
            required: false,
            type: Boolean,
            default: false
        },
        hideFilters: {
            type: Boolean,
            required: false,
            default: false
        },
        viewMode: {
            type: String,
            default: 'default',
            required: false
        }
    },
    components: {
      InlineEntityGroupEditor,
      InlineEntityTagEditor
    },
    data() {
        let offset = parseInt(this.$route.query.offset || 0);
        let limit = parseInt(this.$route.query.limit || 20);

        let current_page = Math.ceil((offset + limit) / limit);
        return  {
            results: [],

            next_url: null,
            loading: false,
            total_results: null,
            current_page: current_page,
            filters: {
                search: this.$route.query.search || null,
                ordering: this.$route.query.ordering || [],
                offset: offset,
                limit: limit,

                tags: this.$route.query.tags || null,
                groups: this.$route.query.groups || null,
                is_foreign: this.$route.query.is_foreign || null,
                active: this.$route.query.active || null,
                entity_type: this.$route.query.entity_type || null
            },

            entity_type_options: [
                {label: 'Company', value: 'company'},
                {label: 'LLP', value: 'llp'},
                {label: 'Partnership', value: 'partnership'},
                {label: 'Individual', value: 'individual'},
                {label: 'HUF', value: 'huf'},
                {label: 'Society', value: 'society'},
                {label: 'AOP', value: 'aop'},
                {label: 'BOI', value: 'boi'},
            ],
            active_options: [
                {label: 'Active Only', value: 'true'},
                {label: 'Inactive Only', value: 'false'},
            ],
            foreign_options: [
                {label: 'Foreign Only', value: 'true'},
                {label: 'Local Only', value: 'false'}
            ],
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        total_pages() {
            if(this.total_results == 0 || this.filters.limit == 0)  return 1
            return Math.ceil(this.total_results/this.filters.limit)
        },
        redoIcon: () => faRedo,
        checkIcon: () => faCheckCircle,
        crossIcon: () => faTimesCircle,
        plusIcon: () => faPlus
    },
    watch: {
        filters: {
            deep: true,
            handler(newV) {
                if (this.current_page == null) return;

                if (this.timer) clearTimeout(this.timer);

                this.timer = setTimeout(() => {
                    this.$router.push({ name: this.$route.name, query: {...newV, ...this.embedded_query_params} });
                }, 800);
            }
        },
        "$route.query"(newV) {
            this.load_data(newV);
        },
        current_page(newV, oldV) {
            if (newV == oldV) return;
            this.filters.offset = (newV - 1) * this.filters.limit;
        }
    },
    methods: {
        init(){
            this.load_data(this.filters)
        },
        calculate_page() {
            if (this.filters.offset == 0) this.current_page = 1;
            else
                this.current_page = Math.ceil(this.filters.offset / this.filters.limit);
        },
        load_data(filters=null) {
            this.loading = true;
            this.$vs.loading();

            let url = this.$store.state.apiendpoints.data_entity_list

            let payload = filters

            if (!payload) payload = {};

            if (this.embedded_mode && this.embedded_filters) {
                payload = { ...payload, ...this.embedded_filters };
            }

            this.api_errors = {}

            Request(this, 'GET', url, payload).then(
                (res) => {
                    this.results = res.results
                    this.total_results = res.count
                    this.next_url = res.next;
                    this.loading = false;
                    this.$vs.loading.close();
                },
                (err) => {
                    this.loading = false;
                    this.$vs.loading.close();
                    if (err.error) {
                        this.api_errors = err.error

                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "Please fix the errors and try again!",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            time: 2500,
                            title: 'Error',
                            text: "Unable to load entities. Please try again later.",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }
            )
        },
        disableEntity(index) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Are you sure?',
                text: 'Are you sure you want to deactivate this entity?',
                accept: () => this.perform_action(index, 'disable'),
                'accept-text': 'Yes',
                'cancel-text': 'No'
            })
        },
        enableEntity(index) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Are you sure?',
                text: 'Are you sure you want to activate this entity?',
                accept: () => this.perform_action(index, 'enable'),
                'accept-text': 'Yes',
                'cancel-text': 'No'
            })
        },
        perform_action(index, action) {
            let payload = { action }

            this.$set(this.results[index], 'submit_disabled', true)

            let url = this.$store.state.apiendpoints.data_entity_action

            Request(this, 'POST', url, {entity_id: this.results[index].id}, payload).then(
                () => {
                    this.$set(this.results[index], 'submit_disabled', false)

                    this.$vs.notify({
                      time: 2500,
                        title: 'Success',
                        text: "Action completed successfully!",
                        iconPack: 'feather',
                        icon: 'icon-check-circle',
                        color: 'success'
                    })

                    if (action == 'disable') {
                      if (this.$store.state.organisation.entity && this.$store.state.organisation.entity.id == this.results[index].id) {
                        this.$store.dispatch('organisation/updateActiveEntity', null)
                        }
                    }

                    this.init()
                },
                err => {
                    this.$set(this.results[index], 'submit_disabled', false)

                    let msg = "An unknown error occurred, please try again!"

                    if (err.error && err.error.action) {
                        msg = err.error.action.join(",")
                    }

                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: msg,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        },
    }
}

</script>
