<template>
  <div>
    <vs-chip v-for="(item, index) in entity.tags" :key="index" @click="removeTag(index)" closable>
      {{ item.name }}
    </vs-chip>
    <a href="#" @click.prevent="popup.active=true">
      <vs-chip>
        + Add
      </vs-chip>
    </a>

    <vs-popup :active.sync="popup.active" title="Add" class="overflow-popup-fix">
      <vs-row>
        <vs-col>
          <SelectForeign
              label="Select Tag"
              :model.sync="popup.item.tag"
              placeholder="Type to search"
              :multiple="false"
              :load_initial="true"
              :label_keys="['name', ]"
              :show_id_in_label="true"
              :endpoint="`${$store.state.apiendpoints.entity_tag_list}`"
              container_class="w-full"
              input_class="bg-white"
              :use_id_as_value="false"
              :disabled="submit_disabled"
          />
          <small class="d-block text-danger">
            {{ popup.api_errors.tag }}
          </small>
        </vs-col>
      </vs-row>
      <vs-row class="mt-3">
        <vs-col class="text-center">
          <vs-button @click="addTag" :disabled="submit_disabled">Add</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { Request } from '@/utils/requests';

export default {
  props: {
    entity: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      submit_disabled: false,

      popup: {
        active: false,
        item: {
          tag: null
        },
        api_errors: {}
      }
    }
  },
  methods: {
    addTag(){
      if (!this.popup.item.tag) {
        this.popup.active = false
      } else {
        let tags = this.entity.tags
        if (tags.find(v => v.id == this.popup.item.tag.id)) {
          this.popup.active = false
        } else {
          tags.push(this.popup.item.tag)
          this.submit(tags)
        }
      }
    },
    removeTag(index) {
      this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Are you sure?',
          text: 'Are you sure you want to remove this tag?',
          accept: () => this._remove_tag(index),
          'accept-text': 'Yes',
          'cancel-text': 'No'
      })
    },
    _remove_tag(index) {
      let tags = this.entity.tags
      tags.splice(index, 1)
      this.submit(tags)
    },
    submit(tags) {
      let url = `${this.$store.state.apiendpoints.data_entity}`

      let payload = { tags }

      this.$vs.loading()
      this.submit_disabled = true
      this.api_errors = {}

      Request(this, 'PATCH', url, {entity_id: this.entity.id}, payload).then(res => {
        this.$vs.loading.close()
        this.submit_disabled = false
        this.popup.active = false

        this.$emit('update', res.tags)

        this.$vs.notify({
          time: 2500,
          title: 'Success',
          text: "Updated Successfully!",
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
      }, err => {
        this.$vs.loading.close()
        this.submit_disabled = false

        let msg = "An unknown error occurred, please try again!"

        if (err.error) {
          this.popup.api_errors = err.error
          msg = "Please fix the errors and try again!"
        }
        this.$vs.notify({
          time: 2500,
          title: 'Error',
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>

<style lang="scss">
  .overflow-popup-fix {
    .vs-popup--content {
      overflow: visible;
    }
  }
</style>
